<template>
  <div class="my-center">
    <div class="left">
      <div class="avatar">
        <img :src="userinfo.head" alt />
      </div>
      <div class="tabs">
        <div
          :class="{ 'tab-item': true, actived: actived === item.id }"
          v-for="item in tabList"
          :key="item.id"
          @click="tabClick(item.id)"
        >
          <span>{{ item.label }}</span>
          <i class="icon el-icon-caret-right"></i>
        </div>
      </div>
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "MyCenter",
  data() {
    return {
      userinfo: { user: {} },
      actived: "account",
      tabList: [
        { id: "orderList", label: "我的订单" },
        { id: "account", label: "账号信息" },
        { id: "address", label: "收货地址" },
        { id: "collect", label: "我的收藏" },
        { id: "giftbag", label: "尊享礼包" },
        { id: "vipbirthday", label: "会员生日" }
      ]
    };
  },
  mounted() {
    // this.userinfo = this.$store.state.user.userdata; //(拿用户信息) this.$store.getter["user/getUid"]（拿到uid）this.$store.getter["user/getToken"]（拿到token）
    this.userinfo = this.$store.getters['user/getuserdata']
    this.actived = this.$route.path.replace('/','')
  },
  methods: {
    tabClick(id) {
      // console.log(id);
      if (id === this.actived) {
          
      }
      this.actived = id;
      this.$router.push({ path:'/' + id });
    }
  }
};
</script>
<style lang="less" scoped>
// @media screen and(min-width:1380px) {
//   .my-center {
//     width: 1480px;
//     margin: 100px auto 200px;
//   }
// }
// @media screen and(max-width:1380px) {
//   .my-center {
//     width: 1080px; /*no*/
//     margin: 100px auto 200px;
//   }
// }
.my-center {
  width: 1480px;
  margin: 100px auto 200px;
  display: flex;
  .left {
    width: 360px;
    margin-right: 64px;
    .avatar {
      width: 172px;
      height: 172px;
      margin: 0 auto;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .tabs {
      padding-top: 48px;
      .tab-item {
        height: 64px;
        font-size: 16px;
        color: #888;
        padding: 0 12px;
        border-top: 1px solid #cfcfcf; /*no*/
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          font-size: 24px;
        }
        &:hover{
          cursor: pointer;
        }
      }
      .tab-item.actived {
        color: #000;
      }
    }
  }
  .right {
    flex: 1;
  }
}
</style>
